<template>
  <div v-hubble="`${status}-toast`">
    <div class="bg-white border flex items-center p-4 rounded-md shadow-sm w-160" :class="colorClasses" role="alert">
      <div class="h-8 inline-flex items-center justify-center rounded-lg shrink-0 w-8" :class="iconClasses">
        <cx-icon :name="icon" size="xl" />
      </div>
      <div v-hubble="'message'" class="break-words font-normal ml-3 overflow-hidden text-sm">
        <slot>{{ message }}</slot>
      </div>
      <button
        v-hubble="'close-button'"
        type="button"
        class="-mx-1.5 -my-1.5 bg-white focus:ring-2 inline-flex ml-auto p-1.5 rounded-lg w-8"
        :class="closeIconClasses"
        :aria-label="$t('ariaLabels.buttons.close')"
        @click="$emit('close')"
      >
        <span class="sr-only">Close</span>
        <cx-icon name="close-fill" size="xl" />
      </button>
    </div>
  </div>
</template>

<script>
import { CxIcon } from '../CxIcon';
import { ERROR, INFO, SUCCESS, WARNING } from '../constants';

export const DEFAULT_STATUS = 'info';
export const STATUSES = [ERROR, INFO, SUCCESS, WARNING];
export const ICON_MAP = {
  [INFO]: 'information-line',
  [SUCCESS]: 'checkbox-circle-line',
  [ERROR]: 'error-warning-line',
  [WARNING]: 'alert-line',
};
export const CLOSE_ICON_CLASSES_MAP = {
  [INFO]: 'text-blue-800 focus:ring-blue-300 h-8 hover:bg-blue-100',
  [SUCCESS]: 'text-green-800 focus:ring-green-300 h-8 hover:bg-green-100',
  [ERROR]: 'text-red-800 focus:ring-red-300 h-8 hover:bg-red-100',
  [WARNING]: 'text-yellow-800 focus:ring-yellow-300 h-8 hover:bg-yellow-100',
};
export const COLOR_CLASSES_MAP = {
  [INFO]: 'border-blue-300 text-blue-800',
  [SUCCESS]: 'border-green-300 text-green-800',
  [ERROR]: 'border-red-300 text-red-800',
  [WARNING]: 'border-yellow-300 text-yellow-800',
};
export const ICON_CLASSES_MAP = {
  [INFO]: 'bg-blue-100 text-blue-500',
  [SUCCESS]: 'bg-green-100 text-green-500',
  [ERROR]: 'bg-red-100 text-red-500',
  [WARNING]: 'bg-yellow-100 text-yellow-500',
};

export default {
  name: 'CxToast',

  hubble: 'cx-toast',

  components: {
    CxIcon,
  },

  props: {
    message: {
      type: String,
      default: '',
    },

    status: {
      type: String,
      default: DEFAULT_STATUS,
      validator: (value) => STATUSES.includes(value),
    },
  },

  computed: {
    closeIconClasses() {
      return CLOSE_ICON_CLASSES_MAP[this.status];
    },

    colorClasses() {
      return COLOR_CLASSES_MAP[this.status];
    },

    icon() {
      return ICON_MAP[this.status];
    },

    iconClasses() {
      return ICON_CLASSES_MAP[this.status];
    },
  },
};
</script>
