<template>
  <div class="fixed right-8 top-notifications z-notifications">
    <transition-group name="toasts">
      <cx-toast
        v-for="toastNotification in toastNotifications"
        :key="toastNotification.id"
        :status="toastNotification.status"
        class="mb-2"
        @close="removeToast(toastNotification.id)"
      >
        <!-- eslint-disable-next-line vue/no-v-html -->
        <span v-html="toastNotification.message" />
      </cx-toast>
    </transition-group>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import { CxToast } from '~/components/cx';

export default {
  name: 'ToastNotifications',

  components: {
    CxToast,
  },

  computed: {
    ...mapState('notifications', ['toastNotifications']),
  },

  methods: {
    ...mapActions('notifications', ['removeToast']),
  },
};
</script>

<style scoped>
.toasts-enter-active,
.toasts-leave-active {
  transition: all 0.3s ease-in-out;
}

.toasts-enter,
.toasts-leave-to {
  @apply opacity-0 translate-x-full;
}
</style>
